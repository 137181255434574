export const ROUTES = {
  HOME: "/",
  QUIZ: "/quiz",
  CONTACT: "/contact",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FEED: "/feed",
  LIKES: "/likes",
  AUTH: "/auth",
  OUTFIT: "/outfit/:outfitId",
};
