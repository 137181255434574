import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import { ROUTES } from "../../../constants/route-contants";
import { useAuth } from "../../../contexts/AuthContext";
import FloatingCard from "../../../components/FloatingCard/FloatingCard";

interface HeroSectionProps {}

const HeroSection: FC<HeroSectionProps> = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const springs = useSpring({
    from: { x: 500 },
    to: { x: 0 },
  });

  function navigateToSignup() {
    navigate(ROUTES.SIGNUP);
  }

  function navigateToFeed() {
    navigate(ROUTES.FEED);
  }

  function navigateToQuiz() {
    navigate(ROUTES.QUIZ);
  }

  return (
    <FloatingCard
      backgroundStyle={{ height: "fit-content", minHeight: "60dvh" }}
      modalStyle={{
        backgroundColor: "inherit",
        boxShadow: "none",
        width: "100%",
        maxWidth: "none",
      }}
    >
      <div className={"text-center"}>
        <h1 className={"display-3 fw-bold text-body-emphasis text-dark mt-5"}>
          <animated.div style={{ ...springs }}>
            <strong>FashionAI</strong>
          </animated.div>
        </h1>
        <h3 className={"display-6 fw-bold text-light"}>
          Where Style Meets Technology
        </h3>
        <div className="display-7 lead mb-4 text-light">
          Expert Curation, Personalized Selections, Time-Optimized Fashion
        </div>
        <div className="col-lg-6 mx-auto">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center p-2">
            <button
              type="button"
              className="btn btn-light btn-lg rounded-pill shadow"
              onClick={navigateToQuiz}
            >
              Take a free quiz
            </button>
            {currentUser ? (
              <button
                type="button"
                className="btn btn-outline-light btn-lg rounded-pill"
                onClick={navigateToFeed}
              >
                My Feed
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-light btn-lg rounded-pill"
                onClick={navigateToSignup}
              >
                Sign Up
              </button>
            )}
          </div>
        </div>
      </div>
    </FloatingCard>
  );
};

export default HeroSection;
