import { FC } from "react";
import styles from "./SkinShadeQuestionComponent.module.scss";
import { QuizDetails } from "../../../models/quizDetails";
import {
  ITERABLE_SKIN_SHADES,
  SKIN_CHART,
} from "../../../constants/form-constants";
import { SkinShade } from "../../../models/enums/skinShade";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

interface SkinShadeQuestionComponentProps {
  formData: QuizDetails | undefined;
  formDataChangeHandler: (newData: QuizDetails) => void;
}

const SkinShadeQuestionComponent: FC<SkinShadeQuestionComponentProps> = ({
  formData,
  formDataChangeHandler,
}) => {
  function updateFormData(skinShadeSelected: SkinShade) {
    logEvent(analytics, "skin_shade_selected", { value: skinShadeSelected });
    formDataChangeHandler({ ...formData, skinShade: skinShadeSelected });
  }

  return (
    <div className={styles.SkinShadeQuestionComponent}>
      <div>
        <div className="text-center pt-3">
          <p className="h3">Select your skin-shade</p>
          <p className="h5 pb-2">( overtone )</p>
          <div className="d-grid gap-3">
            {SKIN_CHART.map((SKIN_TONES, index) => (
              <div
                className={
                  "mx-3 rounded " +
                  (ITERABLE_SKIN_SHADES[index] === formData?.skinShade
                    ? "border border-primary border-5"
                    : "")
                }
                style={{ backgroundColor: SKIN_TONES[2], height: "3rem" }}
                onClick={() => updateFormData(ITERABLE_SKIN_SHADES[index])}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkinShadeQuestionComponent;
