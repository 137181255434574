import { FC } from "react";
import styles from "./StepsSection.module.scss";

interface StepsSectionProps {}

const StepsSection: FC<StepsSectionProps> = () => (
  <div className={styles.StepsSection + " p-5 text-center"}>
    <h1 className={"display-5 fw-bold"}>5 Easy Steps</h1>
    <div className="bg-white rounded col-xs-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 mx-auto p-2 m-3 fw-bold shadow">
      <p>
        Take a quick quiz to share your body shape, height, skin tone, and style
        preferences. Our Fashion AI analyzes your answers to understand your
        unique fashion needs.
      </p>
    </div>
    <div className="bg-white rounded col-xs-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 mx-auto p-2 m-3 fw-bold shadow">
      <p>
        Sit back and let Fashion AI do the work. We'll handpick the perfect
        outfits just for you and display them in your feed.
      </p>
    </div>
    <div className="bg-white rounded col-xs-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 mx-auto p-2 m-3 fw-bold shadow">
      <p>
        Browse through the outfits in your feed and let us know what you love.
        Simply like the outfits you adore, and we'll save them for you.
      </p>
    </div>
    <div className="bg-white rounded col-xs-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 mx-auto p-2 m-3 fw-bold shadow">
      <p>
        Visit your "Likes" page to see all the outfits you've saved. Dive deeper
        into each outfit to explore more details and options.
      </p>
    </div>
    <div className="bg-white rounded col-xs-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 mx-auto p-2 m-3 fw-bold shadow">
      <p>
        Ready to make a purchase? Simply click on the outfit to go to the
        product page. From there, you can buy the items you love and enhance
        your wardrobe effortlessly.
      </p>
    </div>
  </div>
);

export default StepsSection;
