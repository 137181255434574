import { FC } from "react";
import styles from "./HeightQuestionComponent.module.scss";
import { NORMAL, SHORT, TALL } from "../../../constants/form-constants";
import { QuizDetails } from "../../../models/quizDetails";
import { Button } from "react-bootstrap";
import { Height } from "../../../models/enums/height";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

interface HeightQuestionComponentProps {
  formData: QuizDetails | undefined;
  formDataChangeHandler: (newData: QuizDetails) => void;
}

const HeightQuestionComponent: FC<HeightQuestionComponentProps> = ({
  formData,
  formDataChangeHandler,
}) => {
  function updateFormData(heightSelected: Height) {
    logEvent(analytics, "height_selected", { value: heightSelected });
    formDataChangeHandler({ ...formData, height: heightSelected });
  }

  return (
    <div className={styles.HeightQuestionComponent}>
      <div>
        <div className="text-center pt-3">
          <p className="h3 pb-3">Select your height</p>
          <div className="d-grid gap-3">
            <Button
              className="mx-3"
              variant="primary"
              active={SHORT === formData?.height}
              onClick={() => updateFormData(Height.SHORT)}
            >
              Below 5'6"
            </Button>
            <Button
              className="mx-3"
              variant="primary"
              active={NORMAL === formData?.height}
              onClick={() => updateFormData(Height.NORMAL)}
            >
              5'6" to 6'0"
            </Button>
            <Button
              className="mx-3"
              variant="primary"
              active={TALL === formData?.height}
              onClick={() => updateFormData(Height.TALL)}
            >
              Above 6'0"
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeightQuestionComponent;
