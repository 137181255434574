import { FC, useEffect } from "react";
import FloatingCard from "../../components/FloatingCard/FloatingCard";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

interface ContactProps {}

const Contact: FC<ContactProps> = () => {
  useEffect(() => {
    logEvent(analytics, "page_visit", { page: "contact" });
  }, []);
  return (
    <FloatingCard>
      <h5 className={"fw-bold"}>
        Get in Touch, We Don't Bite...
        <br /> Unless You're Made of Chocolate!
      </h5>
      <p>Email: ruhal.doshi@gmail.com</p>
    </FloatingCard>
  );
};

export default Contact;
