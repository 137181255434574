import { FC } from "react";

interface ImageOptionComponentProps {
  active?: boolean;
  onClick?: () => void;
  imageSrc?: string;
  className?: string;
}

const ImageOptionComponent: FC<ImageOptionComponentProps> = ({
  active,
  onClick,
  imageSrc,
  className,
}) => (
  <div className={className} onClick={onClick}>
    <img
      className={
        active
          ? "border border-primary border-3"
          : "border border-grey border-3"
      }
      style={{ cursor: "pointer", margin: "3%", width: "94%" }}
      src={imageSrc}
    />
  </div>
);

export default ImageOptionComponent;
