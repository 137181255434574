import { FC } from "react";
import styles from "./LikeProductImage.module.scss";
import { useQuery } from "@tanstack/react-query";
import { fetchProduct } from "../../../../services/product-service";
import { convertToHttps } from "../../../../utils/common-utils";

interface LikeProductImageProps {
  productId: string;
  index: number;
}

const LikeProductImage: FC<LikeProductImageProps> = ({ productId, index }) => {
  const productQuery = useQuery({
    queryKey: ["product", productId],
    queryFn: () => fetchProduct(productId),
    enabled: productId !== null && productId !== undefined,
  });
  return (
    <div className="p-2">
      {productQuery &&
        !productQuery.isLoading &&
        productQuery.data &&
        productQuery.data.data && (
          <img
            src={"https://" + productQuery.data.data.imageUrl}
            srcSet={convertToHttps(productQuery.data.data.imageSrcSet)}
            key={index}
            className={styles.ThumbnailImage}
          />
        )}
    </div>
  );
};

export default LikeProductImage;
