import { FC } from "react";
import styles from "./Layout.module.scss";
import NavBarComponent from "./Nav/NavBarComponent";
import { Outlet } from "react-router-dom";

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => (
  <div className={styles.Layout}>
    <NavBarComponent />
    <Outlet />
  </div>
);

export default Layout;
