import { FC } from "react";
import styles from "./ProductCard.module.scss";
import { useQuery } from "@tanstack/react-query";
import { fetchProduct } from "../../../services/product-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { convertToHttps } from "../../../utils/common-utils";

interface ProductCardProps {
  productId: string;
}

const ProductCard: FC<ProductCardProps> = ({ productId }) => {
  const productQuery = useQuery({
    queryKey: ["product", productId],
    queryFn: () => fetchProduct(productId),
    enabled: productId !== null && productId !== undefined,
  });

  if (productQuery.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center m-5 p-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (productQuery.isError) {
    return (
      <div className="m-5 p-5">
        Error: {(productQuery.error as Error).message}
      </div>
    );
  }

  const product = productQuery.data;

  const redirectToProductPage = () => {
    const url = product.data.buyLink;
    // open the url in a new tab
    window.open(url, "_blank");
    logEvent(analytics, "redirect_product_page", { productId: productId });
  };

  return (
    <div
      className={`card ${styles.ProductCard} shadow col-lg-3 col-md-6 col-sm-9 col-10 p-3 m-2`}
    >
      <img
        src={`https://${product.data.imageUrl}`}
        srcSet={convertToHttps(product.data.imageSrcSet)}
        className="card-img-top"
        alt={"product"}
      />
      <div className="card-body d-flex flex-column align-items-center">
        <h5 className="card-title">{product.data.subCategory}</h5>
        <p className="card-text">{product.data.brand}</p>
        <button className="btn btn-primary" onClick={redirectToProductPage}>
          GO TO PRODUCT PAGE <FontAwesomeIcon icon={faExternalLinkAlt} />
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
