import { FC, useEffect, useRef, ReactNode, Children } from "react";
import styles from "./Loader.module.scss";
import { useSpring, animated } from "@react-spring/web";
interface LoaderProps {
  children?: ReactNode;
  show: boolean;
}

const Loader: FC<LoaderProps> = ({ children, show }) => {
  const LOOP_LIMIT = 5;
  const reverse = useRef(false);
  const timer = useRef(0);
  const interval = useRef<NodeJS.Timer>();
  const [{ x }, set] = useSpring(() => ({
    from: { x: 0 },
  }));

  useEffect(() => {
    if (show) {
      interval.current = setInterval(increaseX, 1000);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
    }
  }, [show]);

  function increaseX() {
    set({ x: timer.current });
    // console.log(timer.current, reverse.current);
    if (timer.current === LOOP_LIMIT - 1 && !reverse.current) {
      reverse.current = !reverse.current;
    }

    if (timer.current === 0 && reverse.current) {
      reverse.current = !reverse.current;
    }
    timer.current = reverse.current ? timer.current - 1 : timer.current + 1;
  }

  if (show) {
    return (
      <div className={styles.Loader}>
        {Children && children}
        <div className={styles.BlobBackground}>
          <svg className={styles.Blob} id="visual" overflow="visible">
            <g>
              <animated.path
                d={x.to({
                  range: [0, 1, 2, 3, 4],
                  output: [
                    "M72.5 -87.1C91.9 -70.2 104 -45.3 105.4 -21.1C106.7 3.2 97.2 26.9 85.2 50.7C73.3 74.5 58.9 98.3 37 109.8C15.2 121.2 -14.1 120.3 -41 111.2C-67.9 102.1 -92.4 84.8 -102.6 61.8C-112.7 38.9 -108.6 10.2 -102.3 -16.7C-95.9 -43.7 -87.5 -69 -70.1 -86.2C-52.7 -103.5 -26.4 -112.7 0.1 -112.9C26.6 -113 53.1 -104 72.5 -87.1",
                    "M69 -86.9C86.5 -67.5 96 -43 101.1 -17.3C106.3 8.4 107.3 35.5 95.5 54.4C83.7 73.4 59.2 84.3 34.6 91.7C10.1 99.1 -14.4 103.1 -37.3 97.1C-60.1 91.1 -81.1 75 -91.2 54.3C-101.3 33.5 -100.4 8 -96.5 -17.6C-92.7 -43.1 -85.9 -68.7 -69.3 -88.2C-52.7 -107.8 -26.4 -121.4 -0.3 -121C25.7 -120.6 51.4 -106.3 69 -86.9",
                    "M64.3 -80.6C81.8 -61.9 93.6 -40.2 101.3 -15.1C108.9 9.9 112.5 38.5 102.6 62.7C92.8 87 69.5 106.9 44 112.7C18.5 118.4 -9.2 109.9 -35.3 99.4C-61.3 88.8 -85.6 76.2 -96.1 56.3C-106.6 36.5 -103.1 9.4 -95.6 -14C-88.1 -37.5 -76.5 -57.4 -59.9 -76.3C-43.3 -95.2 -21.6 -113.1 0.9 -114.1C23.4 -115.2 46.7 -99.3 64.3 -80.6",
                    "M76.7 -88.4C97.2 -74.1 110.1 -47.9 110.7 -22.8C111.3 2.4 99.5 26.5 85.1 46.9C70.7 67.3 53.6 83.9 31.3 96.6C9 109.3 -18.6 118.1 -42.1 111.4C-65.7 104.7 -85.3 82.6 -99.1 57.7C-112.9 32.8 -120.9 5 -117.3 -21.6C-113.6 -48.1 -98.3 -73.5 -76.8 -87.5C-55.3 -101.5 -27.6 -104.3 0.2 -104.5C28.1 -104.8 56.1 -102.6 76.7 -88.4",
                    "M62.1 -77.5C80.8 -58.3 96.5 -39.1 102.9 -16.2C109.2 6.6 106.1 33.1 95.1 57.4C84 81.6 64.9 103.8 41.5 110.9C18.1 118.1 -9.6 110.3 -35.4 99.8C-61.2 89.3 -85.2 76.1 -95.9 56.1C-106.6 36 -104 9.1 -99 -17.1C-94 -43.3 -86.5 -68.8 -69.6 -88.3C-52.7 -107.8 -26.4 -121.4 -2.3 -118.6C21.7 -115.9 43.4 -96.7 62.1 -77.5",
                  ],
                })}
                fill="#ff799a"
                // stroke="#ff799a"
                // stroke-width="9"
              />
            </g>
          </svg>
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

export default Loader;
