import { FC, useEffect, useState } from "react";
import GenderQuestionComponent from "./GenderQuestionComponent/GenderQuestionComponent";
import HeightQuestionComponent from "./HeightQuestionComponent/HeightQuestionComponent";
import BodyShapeQuestionComponent from "./BodyShapeQuestionComponent/BodyShapeQuestionComponent";
import { QuizDetails } from "../../models/quizDetails";
import { TOTAL_QUESTIONS } from "../../constants/form-constants";
import { useAppSelector } from "../../hooks/app-selector";
import { selectQuiz, updateQuiz } from "../../slices/quiz-slice";
import { useAppDispatch } from "../../hooks/app-dispatch";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/route-contants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendQuizData } from "../../services/quiz-service";
import Loader from "../../components/Loader/Loader";
import { useAuth } from "../../contexts/AuthContext";
import SkinShadeQuestionComponent from "./SkinShadeQuestionComponent/SkinShadeQuestionComponent";
import SkinToneQuestionComponent from "./SkinToneQuestionComponent/SkinToneQuestionComponent";
import AuthenticationForm from "../AuthenticationPage/AuthenticationForm/AuthenticationForm";
import FloatingCard from "../../components/FloatingCard/FloatingCard";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

interface QuizProps {}

const Quiz: FC<QuizProps> = () => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(selectQuiz);
  const navigate = useNavigate();
  const [formIndex, setFormIndex] = useState(0);
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const quizMutation = useMutation({
    mutationFn: (newQuizData: QuizDetails) => {
      return sendQuizData(newQuizData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["feed"]);
      navigate(ROUTES.FEED);
      logEvent(analytics, "quiz_completed");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    logEvent(analytics, "page_visit", { page: "quiz" });
  }, []);

  useEffect(() => {
    if (currentUser && formIndex >= TOTAL_QUESTIONS && formData) {
      quizMutation.mutate({ ...formData, userId: currentUser.userId });
    }
  }, [formIndex, currentUser]);

  function updateFormData(newData: QuizDetails) {
    setFormIndex(formIndex + 1);
    dispatch(updateQuiz(newData));
  }

  function goBack() {
    setFormIndex(formIndex - 1);
  }

  function calculateProgressPercent() {
    return ((formIndex * 100) / TOTAL_QUESTIONS).toString() + "%";
  }

  function renderComponentAtIndex(index: number): JSX.Element {
    switch (index) {
      case 0:
        return (
          <GenderQuestionComponent
            formData={formData}
            formDataChangeHandler={updateFormData}
          />
        );
      case 1:
        return (
          <HeightQuestionComponent
            formData={formData}
            formDataChangeHandler={updateFormData}
          />
        );
      case 2:
        return (
          <BodyShapeQuestionComponent
            formData={formData}
            formDataChangeHandler={updateFormData}
          />
        );
      case 3:
        return (
          <SkinShadeQuestionComponent
            formData={formData}
            formDataChangeHandler={updateFormData}
          />
        );
      case 4:
        return (
          <SkinToneQuestionComponent
            formData={formData}
            formDataChangeHandler={updateFormData}
          />
        );
      default:
        if (currentUser) {
          return <Loader show />;
        } else {
          return <AuthenticationForm />;
        }
    }
  }

  return (
    <FloatingCard>
      <div className="progress">
        <div
          className="progress-bar"
          style={{ width: calculateProgressPercent() }}
        ></div>
      </div>
      {renderComponentAtIndex(formIndex)}
      {formIndex > 0 && formIndex < TOTAL_QUESTIONS && (
        <div key={formIndex} className="pt-3 ps-3">
          <button
            type="button"
            className="btn btn-outline-primary text-primary"
            onClick={goBack}
          >
            GO BACK
          </button>
        </div>
      )}
    </FloatingCard>
  );
};

export default Quiz;
