import { FC } from "react";
import styles from "./LikeCard.module.scss";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getOutfit } from "../../../services/outfit-service";
import LikeProductImage from "./LikeProductImage/LikeProductImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Like } from "../../../models/like";
import { deleteLike } from "../../../services/like-service";
import { useNavigate } from "react-router-dom";

interface LikeCardProps {
  like: Like;
}

const LikeCard: FC<LikeCardProps> = ({ like }) => {
  const queryClient = useQueryClient();

  const outfitQuery = useQuery({
    queryKey: ["outfit", like.outfitId],
    queryFn: () => getOutfit(like.outfitId),
    enabled: like.outfitId !== null && like.outfitId !== undefined,
  });

  const deleteMutation = useMutation((like: Like) => deleteLike(like), {
    onSuccess: () => {
      queryClient.invalidateQueries(["likes"]);
    },
  });

  const navigate = useNavigate();

  const handleDelete = () => {
    deleteMutation.mutate(like);
  };

  const navigateToOutfitPage = () => {
    navigate(`/outfit/${like.outfitId}`);
  };

  return (
    <div className={styles.LikeCard}>
      {!outfitQuery.isLoading &&
        outfitQuery.data &&
        outfitQuery.data.data &&
        outfitQuery.data.data.productIds.length > 0 && (
          <div className="card shadow m-3">
            <div className="row align-items-center">
              <div className="col">
                <div
                  id="image-row"
                  className="row"
                  onClick={navigateToOutfitPage}
                  style={{ cursor: "pointer" }}
                >
                  {outfitQuery.data.data.productIds.map((productId, index) => (
                    <div className="col-6" key={index}>
                      <LikeProductImage productId={productId} index={index} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-auto" id="delete-button">
                <button type="button" className="btn" onClick={handleDelete}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-danger p-2"
                  />
                </button>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default LikeCard;
