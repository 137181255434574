import { Fragment, FC, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { AuthenticationState } from "../../../models/enums/authenticationState";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../constants/route-contants";
import { Spinner } from "react-bootstrap";

interface AuthenticationFormProps {}

const AuthenticationForm: FC<AuthenticationFormProps> = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { handleSignIn, verifyCode, authenticationState } = useAuth();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      setPhoneNumber(newValue);
    }
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      setOtp(newValue);
    }
  };

  const handleSendOtp = () => {
    setError("");
    setIsLoading(true);
    handleSignIn(`+91${phoneNumber}`)
      .then((response) => {
        if (response.error) {
          setError(response.error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleVerifyOtp = () => {
    setError("");
    setIsLoading(true);
    verifyCode(otp)
      .then((response) => {
        if (response.error) {
          setError(response.error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {error && (
        <div id="error" className="alert alert-danger">
          {error}
        </div>
      )}

      <h3 className="text-center mb-3 fw-bold">Log In</h3>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Fragment>
          {authenticationState === AuthenticationState.NotAuthenticated && (
            <Fragment>
              <div className="form-group mb-3">
                <label htmlFor="phoneNumber">Phone Number</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">+91</span>
                  </div>
                  <input
                    type="tel"
                    id="phoneNumber"
                    className="form-control"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={10}
                  />
                </div>
              </div>
              <div className="form-group d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSendOtp}
                  disabled={phoneNumber.length != 10}
                >
                  Send OTP
                </button>
              </div>
            </Fragment>
          )}
          {authenticationState === AuthenticationState.VerificationRequired && (
            <Fragment>
              <div className="form-group mb-3">
                <label htmlFor="otp">OTP</label>
                <input
                  type="text"
                  id="otp"
                  className="form-control"
                  value={otp}
                  onChange={handleOtpChange}
                  maxLength={10}
                />
              </div>
              <div className="form-group d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleVerifyOtp}
                  disabled={otp.length < 1}
                >
                  Verify OTP
                </button>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
      {authenticationState === AuthenticationState.Authenticated && (
        <Navigate to={ROUTES.FEED} />
      )}
    </>
  );
};

export default AuthenticationForm;
