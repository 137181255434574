import { FC, useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { fetchUserFeed } from "../../services/feed-service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FeedItemComponent from "./FeedItem/FeedItem";
import { Like } from "../../models/like";
import { postLike } from "../../services/like-service";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/route-contants";
import { AxiosError } from "axios";
import FloatingCard from "../../components/FloatingCard/FloatingCard";
import { Spinner } from "react-bootstrap";

interface FeedProps {}

const Feed: FC<FeedProps> = () => {
  const { currentUser } = useAuth();
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const feedQuery = useQuery({
    queryKey: ["feed", currentUser?.userId],
    queryFn: () => fetchUserFeed(currentUser?.userId),
    enabled: currentUser?.userId !== null && currentUser?.userId !== undefined,
  });

  const likeMutation = useMutation((like: Like) => postLike(like), {
    onSuccess: () => {
      queryClient.invalidateQueries(["likes"]);
      if (feedQuery.data) {
        setCurrentIndex(
          (currentIndex + 1) % feedQuery.data.data.outfitIds.length
        );
      }
    },
  });

  useEffect(() => {
    logEvent(analytics, "page_visit", { page: "feed" });
  }, []);

  useEffect(() => {
    if (!feedQuery.isLoading && feedQuery.data?.data && currentIndex < 0) {
      setCurrentIndex(0);
    }

    if (
      feedQuery.isError &&
      feedQuery.error instanceof AxiosError &&
      "response" in feedQuery.error &&
      feedQuery.error.response?.status === 412
    ) {
      navigate(ROUTES.QUIZ);
    }
  }, [feedQuery, feedQuery.isError, feedQuery.error]);

  const handleLike = (outfitId: string) => {
    console.log(`You liked:`, outfitId);
    likeMutation.mutate({
      userId: currentUser?.userId,
      outfitId: outfitId,
    });
    logEvent(analytics, "outfit_liked", {
      outfitId: outfitId,
    });
  };

  const handleDislike = (outfitId: string) => {
    console.log(`You disliked:`, outfitId);
    if (feedQuery.data) {
      setCurrentIndex(
        (currentIndex + 1) % feedQuery.data.data.outfitIds.length
      );
    }
    logEvent(analytics, "outfit_disliked", {
      outfitId: outfitId,
    });
  };

  return (
    <FloatingCard>
      {feedQuery.isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {feedQuery.data && feedQuery.data.data && (
            <div>
              <FeedItemComponent
                outfitId={feedQuery.data.data.outfitIds[currentIndex]}
                handleLike={handleLike}
                handleDislike={handleDislike}
                key={feedQuery.data.data.outfitIds[currentIndex]}
              />
            </div>
          )}
        </>
      )}
    </FloatingCard>
  );
};

export default Feed;
