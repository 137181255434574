import { AxiosPromise } from "axios";
import { httpGet } from "./rest-service";
import { ProductInfo } from "../models/productInfo";

export function fetchProduct(
  productId: string | null | undefined
): AxiosPromise<ProductInfo> {
  console.log("fetching product:", productId);
  return httpGet<ProductInfo>(`/api/product/${productId}`);
}
