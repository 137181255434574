import { FC } from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "./FeaturesSection.module.scss";

interface FeaturesSectionProps {}

const FeaturesSection: FC<FeaturesSectionProps> = () => (
  <div className={"container-fluid " + styles.FeaturesSection}>
    <Carousel interval={5000} variant="dark" controls={false}>
      <Carousel.Item>
        <div className={"p-5 text-center " + styles.FeaturesCarouselItem}>
          <img
            src="/illustrations/features/1-professional.svg"
            className={"mx-auto d-block " + styles.FeaturesIllustrations}
          />
          <p
            className={
              "mx-auto d-block m-3 col-xs-10 col-sm-10 col-md-8 col-lg-6 col-xl-6 " +
              styles.FeaturesDescription
            }
          >
            Experts at Fashion AI goes beyond mere recommendations and
            meticulously filter through hundreds of brands to curate a selection
            tailored just for you.
          </p>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className={"p-5 text-center " + styles.FeaturesCarouselItem}>
          <img
            src="/illustrations/features/2-personalization.svg"
            className={"mx-auto d-block " + styles.FeaturesIllustrations}
          />
          <p
            className={
              "mx-auto d-block m-3 col-xs-10 col-sm-10 col-md-8 col-lg-6 col-xl-6 " +
              styles.FeaturesDescription
            }
          >
            Fashion AI uses advanced algorithms to analyze your features and
            then selects outfits that complement your unique features and style
            preferences, offering a personalized shopping experience.
          </p>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className={"p-5 text-center " + styles.FeaturesCarouselItem}>
          <img
            src="/illustrations/features/3-trend.svg"
            className={"mx-auto d-block " + styles.FeaturesIllustrations}
          />
          <p
            className={
              "mx-auto d-block m-3 col-xs-10 col-sm-10 col-md-8 col-lg-6 col-xl-6 " +
              styles.FeaturesDescription
            }
          >
            Fashion AI is your personal trendsetter, keeping up with fashion
            trends so you don't have to. Our team identifies the latest trends
            to ensure fresh and stylish outfit recommendations.
          </p>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className={"p-5 text-center " + styles.FeaturesCarouselItem}>
          <img
            src="/illustrations/features/4-quality.svg"
            className={"mx-auto d-block " + styles.FeaturesIllustrations}
          />
          <p
            className={
              "mx-auto d-block m-3 col-xs-10 col-sm-10 col-md-8 col-lg-6 col-xl-6 " +
              styles.FeaturesDescription
            }
          >
            Fashion AI prioritizes quality, handpicking brands known for
            excellent craftsmanship and materials. We ensure every garment meets
            the highest standards, prioritizing your satisfaction and
            confidence.
          </p>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className={"p-5 text-center " + styles.FeaturesCarouselItem}>
          <img
            src="/illustrations/features/6-time.svg"
            className={"mx-auto d-block " + styles.FeaturesIllustrations}
          />
          <p
            className={
              "mx-auto d-block m-3 col-xs-10 col-sm-10 col-md-8 col-lg-6 col-xl-6 " +
              styles.FeaturesDescription
            }
          >
            Fashion AI uses advanced algorithms to scan thousands of products,
            selecting the best options for you. Our AI technology streamlines
            shopping, saving you time and reducing decision fatigue.
          </p>
        </div>
      </Carousel.Item>
    </Carousel>
  </div>
);

export default FeaturesSection;
