import { FC, useEffect, useRef, useState } from "react";
import styles from "./NavBarComponent.module.scss";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { ROUTES } from "../../../constants/route-contants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { fetchUserLike } from "../../../services/like-service";

interface NavBarComponentProps {}

const NavBarComponent: FC<NavBarComponentProps> = () => {
  const navigate = useNavigate();
  const { currentUser, handleSignOut: logout } = useAuth();
  const [expanded, setExpanded] = useState(false);
  const likesQuery = useQuery({
    queryKey: ["likes"],
    queryFn: () => fetchUserLike(currentUser),
    enabled: currentUser !== null && currentUser !== undefined,
  });
  const navBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleEvent(event: any) {
      if (navBarRef.current && !navBarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    // Bind the event listeners
    document.addEventListener("mousedown", handleEvent);
    document.addEventListener("touchstart", handleEvent);
    return () => {
      // Unbind the event listeners on clean up
      document.removeEventListener("mousedown", handleEvent);
      document.removeEventListener("touchstart", handleEvent);
    };
  }, []);

  function navigateToQuiz() {
    setExpanded(false);
    navigate(ROUTES.QUIZ);
  }

  function navigateToHome() {
    setExpanded(false);
    navigate(ROUTES.HOME);
  }

  function navigateToContact() {
    setExpanded(false);
    navigate(ROUTES.CONTACT);
  }

  function navigateToFeed() {
    setExpanded(false);
    navigate(ROUTES.FEED);
  }

  function navigateToLogin() {
    setExpanded(false);
    navigate(ROUTES.LOGIN);
  }

  function navigateToLikes() {
    setExpanded(false);
    navigate(ROUTES.LIKES);
  }

  async function logoutUser() {
    try {
      await logout();
      setExpanded(false);
      navigate(ROUTES.LOGIN);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={styles.NavBarComponent} ref={navBarRef}>
      <Navbar
        bg="light"
        expand="lg"
        fixed="top"
        onToggle={() => setExpanded((oldState) => !oldState)}
        expanded={expanded}
      >
        <Container>
          <Navbar.Brand onClick={navigateToHome}>
            <b>FashionAI</b>
          </Navbar.Brand>
          {currentUser && (
            <Nav className="ms-auto pe-3 justify-content-end">
              <Nav.Link onClick={navigateToLikes} className={styles.IconLink}>
                <FontAwesomeIcon icon={faHeart} size="xl" />
                {likesQuery &&
                  !likesQuery.isLoading &&
                  likesQuery.data &&
                  likesQuery.data.data &&
                  likesQuery.data.data.length > 0 && (
                    <span className={styles.IconBadge}>
                      {likesQuery.data.data.length > 9
                        ? "+9"
                        : likesQuery.data.data.length}
                    </span>
                  )}
              </Nav.Link>
            </Nav>
          )}

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Link onClick={navigateToHome}>Home</Nav.Link>
              <Nav.Link onClick={navigateToQuiz}>Take a quiz</Nav.Link>
              <Nav.Link onClick={navigateToContact}>Contact Us</Nav.Link>
              {currentUser ? (
                <>
                  <Nav.Link onClick={navigateToFeed}>Feed</Nav.Link>
                  <Nav.Link onClick={logoutUser}>Log Out</Nav.Link>
                </>
              ) : (
                <Nav.Link onClick={navigateToLogin}>Log In</Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBarComponent;
