import { FC, useEffect } from "react";
import styles from "./Likes.module.scss";
import LikeCard from "./LikeCard/LikeCard";
import { useQuery } from "@tanstack/react-query";
import { fetchUserLike } from "../../services/like-service";
import { useAuth } from "../../contexts/AuthContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

interface LikesProps {}

const Likes: FC<LikesProps> = () => {
  const { currentUser } = useAuth();

  const likesQuery = useQuery({
    queryKey: ["likes"],
    queryFn: () => fetchUserLike(currentUser),
    enabled: currentUser?.userId !== null && currentUser?.userId !== undefined,
  });

  useEffect(() => {
    logEvent(analytics, "page_visit", { page: "likes" });
  }, []);

  return (
    <div className={styles.Likes}>
      <div className={styles.LikesContainer}>
        <div className="mt-5 pt-5 pb-3 mx-auto" style={{ maxWidth: "560px" }}>
          <h3 className="display-6 fw-bold text-light">Your Liked Outfits</h3>
        </div>

        {!likesQuery.isLoading &&
          likesQuery.data &&
          likesQuery.data.data &&
          likesQuery.data.data &&
          likesQuery.data.data.length > 0 &&
          likesQuery.data.data.map((like) => (
            <LikeCard key={like.outfitId} like={like} />
          ))}
      </div>
    </div>
  );
};

export default Likes;
