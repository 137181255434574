import { AxiosPromise } from "axios";
import { Feed } from "../models/feed";
import { httpGet } from "./rest-service";

export function fetchUserFeed(
  userId: string | null | undefined
): AxiosPromise<Feed> {
  console.log("fetching feed for userId:", userId);
  return httpGet<Feed>(`api/feed/${userId}`);
}
