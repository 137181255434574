import { FC, useEffect, useState } from "react";
import styles from "./FeedItem.module.scss";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import { getOutfit } from "../../../services/outfit-service";
import { useQuery } from "@tanstack/react-query";
import ProductImage from "./ProductImage/ProductImage";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface FeedItemProps {
  outfitId: string;
  handleLike: (outfitId: string) => void;
  handleDislike: (outfitId: string) => void;
}

const FeedItemComponent: FC<FeedItemProps> = ({
  outfitId,
  handleDislike,
  handleLike,
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const outfitQuery = useQuery({
    queryKey: ["outfit", outfitId],
    queryFn: () => getOutfit(outfitId),
    enabled: outfitId !== null && outfitId !== undefined,
  });

  useEffect(() => {
    logEvent(analytics, "outfit_viewed", { outfitId: outfitId });
  }, []);

  return (
    <div className={styles.FeedItem}>
      {outfitQuery &&
        !outfitQuery.isLoading &&
        outfitQuery.data &&
        outfitQuery.data.data && (
          <Container className="p-0">
            <Row className="flex-column flex-md-row">
              <Col
                xs={12}
                md={9}
                id="MainSection"
                className="justify-content-md-center px-2 py-1"
              >
                <Carousel
                  variant="dark"
                  activeIndex={activeItemIndex}
                  onSelect={(eventKey) => {
                    setActiveItemIndex(eventKey);
                  }}
                  className="position-relative"
                >
                  {outfitQuery.data.data.productIds.map((productId, index) => (
                    <Carousel.Item key={index}>
                      <ProductImage productId={productId} index={index} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
              <Col
                xs={12}
                md={3}
                id="SecondarySection"
                className="justify-content-center px-2 py-1"
              >
                <Row>
                  {outfitQuery.data.data.productIds.map((productId, index) => (
                    <Col xs={3} md={12} key={index}>
                      <div
                        className={
                          index === activeItemIndex
                            ? "border border-3 border-primary"
                            : ""
                        }
                        onClick={() => {
                          setActiveItemIndex(index);
                        }}
                      >
                        <ProductImage productId={productId} index={index} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="px-2 pt-2">
                <Button
                  variant="outline-danger"
                  className="w-100"
                  onClick={() => handleDislike(outfitId)}
                >
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </Button>
              </Col>
              <Col xs={6} className="px-2 pt-2">
                <Button
                  variant="outline-primary"
                  className="w-100"
                  onClick={() => handleLike(outfitId)}
                >
                  <FontAwesomeIcon icon={faHeart} size="lg" />
                </Button>
              </Col>
            </Row>
          </Container>
        )}
    </div>
  );
};

export default FeedItemComponent;
