import { FC } from "react";
import styles from "./SkinToneQuestionComponent.module.scss";
import { QuizDetails } from "../../../models/quizDetails";
import {
  ITERABLE_SKIN_SHADES,
  ITERABLE_SKIN_TONES,
  SKIN_CHART,
} from "../../../constants/form-constants";
import { SkinTone } from "../../../models/enums/skinTone";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

interface SkinToneQuestionComponentProps {
  formData: QuizDetails | undefined;
  formDataChangeHandler: (newData: QuizDetails) => void;
}

const SkinToneQuestionComponent: FC<SkinToneQuestionComponentProps> = ({
  formData,
  formDataChangeHandler,
}) => {
  const SKIN_TONE_OPTIONS_INDEX = formData?.skinShade
    ? ITERABLE_SKIN_SHADES.indexOf(formData.skinShade)
    : undefined;

  const SKIN_TONE_OPTIONS =
    SKIN_TONE_OPTIONS_INDEX !== undefined && SKIN_TONE_OPTIONS_INDEX !== null
      ? SKIN_CHART[SKIN_TONE_OPTIONS_INDEX]
      : SKIN_CHART[2];

  function updateFormData(skinToneSelected: SkinTone) {
    logEvent(analytics, "skin_tone_selected", { value: skinToneSelected });
    formDataChangeHandler({ ...formData, skinTone: skinToneSelected });
  }

  return (
    <div className={styles.SkinToneQuestionComponent}>
      <div>
        <div className="text-center pt-3">
          <p className="h3">Select your skin-tone</p>
          <p className="h5 pb-2">( undertone )</p>
          <div className="d-grid gap-3">
            {SKIN_TONE_OPTIONS.map((opt, index) => (
              <div
                className={
                  "mx-3 rounded " +
                  (ITERABLE_SKIN_TONES[index] === formData?.skinTone
                    ? "border border-primary border-5"
                    : "")
                }
                style={{ backgroundColor: opt, height: "3rem" }}
                onClick={() => updateFormData(ITERABLE_SKIN_TONES[index])}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkinToneQuestionComponent;
