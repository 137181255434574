import { FC } from "react";
import styles from "./FloatingCard.module.scss";

interface FloatingCardProps {
  children: React.ReactNode;
  backgroundStyle?: React.CSSProperties;
  modalStyle?: React.CSSProperties;
}

const FloatingCard: FC<FloatingCardProps> = ({
  children,
  backgroundStyle,
  modalStyle,
}) => (
  <div className={styles.FloatingCardBackground} style={backgroundStyle}>
    <div className={styles.FloatingCardModal} style={modalStyle}>
      {children}
    </div>
  </div>
);

export default FloatingCard;
