import { FC } from "react";
import { fetchProduct } from "../../../../services/product-service";
import { useQuery } from "@tanstack/react-query";
import { convertToHttps } from "../../../../utils/common-utils";

interface ProductImageProps {
  productId: string;
  index: number;
}

const ProductImage: FC<ProductImageProps> = ({ productId, index }) => {
  const productQuery = useQuery({
    queryKey: ["product", productId],
    queryFn: () => fetchProduct(productId),
    enabled: productId !== null && productId !== undefined,
  });

  return (
    <>
      {productQuery &&
        !productQuery.isLoading &&
        productQuery.data &&
        productQuery.data.data && (
          <img
            key={index}
            src={"https://" + productQuery.data.data.imageUrl}
            srcSet={convertToHttps(productQuery.data.data.imageSrcSet)}
            className="img-fluid"
          />
        )}
    </>
  );
};

export default ProductImage;
