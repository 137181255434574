import { FC, useEffect } from "react";
import HeroSection from "./HeroSection/HeroSection";
import FeaturesSection from "./FeaturesSection/FeaturesSection";
import StepsSection from "./StepsSection/StepsSection";
import CallToActionSection from "./CallToActionSection/CallToActionSection";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
interface HomeProps {}

const Home: FC<HomeProps> = () => {
  useEffect(() => {
    logEvent(analytics, "page_visit", { page: "home" });
  }, []);

  return (
    <div>
      <HeroSection />
      <FeaturesSection />
      <StepsSection />
      <CallToActionSection />
    </div>
  );
};

export default Home;
