import { FC, useEffect } from "react";
import styles from "./OutfitPage.module.scss";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getOutfit } from "../../services/outfit-service";
import ProoductCard from "./ProductCard/ProductCard";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

interface OutfitPageProps {}

const OutfitPage: FC<OutfitPageProps> = () => {
  const { outfitId } = useParams();
  const outfitQuery = useQuery({
    queryKey: ["outfit", outfitId],
    queryFn: () => getOutfit(outfitId),
    enabled: outfitId !== null && outfitId !== undefined,
  });

  useEffect(() => {
    logEvent(analytics, "page_visit", { page: "outfit", outfitId: outfitId });
  }, []);

  if (outfitQuery.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center m-5 p-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.OutfitPage}>
      <div
        id="products"
        className="d-flex flex-column align-items-center w-100 mt-5 pt-3"
      >
        {outfitQuery.data?.data.productIds.map((productId, index) => (
          <ProoductCard productId={productId} key={index} />
        ))}
      </div>
    </div>
  );
};

export default OutfitPage;
