import { FC } from "react";
import styles from "./GenderQuestionComponent.module.scss";
import { QuizDetails } from "../../../models/quizDetails";
import { Button } from "react-bootstrap";
import { Gender } from "../../../models/enums/gender";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

interface GenderQuestionComponentProps {
  formData: QuizDetails | undefined;
  formDataChangeHandler: (newData: QuizDetails) => void;
}

const GenderQuestionComponent: FC<GenderQuestionComponentProps> = ({
  formData,
  formDataChangeHandler,
}) => {
  function updateFormData(genderSelected: Gender) {
    logEvent(analytics, "gender_selected", { value: genderSelected });
    formDataChangeHandler({ ...formData, gender: genderSelected });
  }

  return (
    <div className={styles.GenderQuestionComponent}>
      <div>
        <div className="text-center pt-3">
          <p className="h3 pb-3">You identify as</p>
          <div className="d-grid gap-3">
            <Button
              className="mx-3"
              variant="primary"
              active={Gender.MALE === formData?.gender}
              onClick={() => updateFormData(Gender.MALE)}
            >
              Male
            </Button>
            <Button
              className="mx-3"
              variant="primary"
              active={Gender.FEMALE === formData?.gender}
              disabled={true}
              onClick={() => updateFormData(Gender.FEMALE)}
            >
              Female
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenderQuestionComponent;
