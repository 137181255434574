import { FC, ReactNode } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../constants/route-contants";

interface PrivateProps {
  children: ReactNode;
}

const Private: FC<PrivateProps> = ({ children }) => {
  const { currentUser } = useAuth();

  return currentUser ? <>{children}</> : <Navigate to={ROUTES.LOGIN} />;
};

export default Private;
