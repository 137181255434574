import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuizDetails } from "../models/quizDetails";
import { RootState } from "../store/store";

interface quizState {
  value?: QuizDetails;
}

const initialState: quizState = {};

export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    updateQuiz: (state, action: PayloadAction<QuizDetails | undefined>) => {
      state.value = action.payload;
    },
  },
});

export const { updateQuiz } = quizSlice.actions;

export const selectQuiz = (state: RootState) => state.quiz.value;
export default quizSlice.reducer;
