import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Quiz from "./pages/Quiz/Quiz";
import Contact from "./pages/Contact/Contact";
import Layout from "./components/Layout/Layout";
import { AuthProvider } from "./contexts/AuthContext";
import Feed from "./pages/Feed/Feed";
import Private from "./components/Private/Private";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ROUTES } from "./constants/route-contants";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Likes from "./pages/Likes/Likes";
import AuthenticationPage from "./pages/AuthenticationPage/AuthenticationPage";
import OutfitPage from "./pages/OutfitPage/OutfitPage";

function App() {
  // can provide base configs here
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 15 * 60 * 1000, // 15 minutes
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path={ROUTES.HOME} element={<Layout />}>
                <Route index element={<Home />} />
                <Route path={ROUTES.QUIZ} element={<Quiz />} />
                <Route path={ROUTES.CONTACT} element={<Contact />} />
                <Route path={ROUTES.LOGIN} element={<AuthenticationPage />} />
                <Route path={ROUTES.SIGNUP} element={<AuthenticationPage />} />
                <Route
                  path={ROUTES.FEED}
                  element={
                    <Private>
                      <Feed />
                    </Private>
                  }
                />
                <Route
                  path={ROUTES.LIKES}
                  element={
                    <Private>
                      <Likes />
                    </Private>
                  }
                />
                <Route path={ROUTES.AUTH} element={<AuthenticationPage />} />
                <Route path={ROUTES.OUTFIT} element={<OutfitPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
