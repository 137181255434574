import { FC, createContext, useContext, useEffect, useState } from "react";
import {
  autoSignIn,
  confirmSignIn,
  signIn,
  signUp,
  signOut,
  AuthUser,
  getCurrentUser,
} from "aws-amplify/auth";
import { AuthenticationState } from "../models/enums/authenticationState";
import { analytics } from "../firebase";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";

type AuthContextValue = {
  currentUser: AuthUser | null | undefined;
  handleSignIn: (phoneNumber: string) => Promise<AuthResponse>;
  handleSignOut: () => Promise<AuthResponse>;
  verifyCode: (code: string) => Promise<AuthResponse>;
  authenticationState: AuthenticationState;
};

type AuthResponse = {
  error?: string;
};

const defaultAuthContextValue: AuthContextValue = {
  currentUser: null,
  handleSignIn: async () => {
    throw new Error("handleSignIn function not implemented");
  },
  handleSignOut: async () => {
    throw new Error("handleSignOut function not implemented");
  },
  verifyCode: async () => {
    throw new Error("verifyCode function not implemented");
  },
  authenticationState: AuthenticationState.NotAuthenticated,
};

const AuthContext = createContext<AuthContextValue>(defaultAuthContextValue);

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider: FC<any> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<AuthUser | null>();
  const [authStep, setAuthStep] = useState<string>("");
  const [authenticationState, setAuthenticationState] =
    useState<AuthenticationState>(AuthenticationState.NotAuthenticated);
  const [loading, setLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    fetchUser();
  }, []);

  async function fetchUser() {
    try {
      setLoading(true);
      const user = await getCurrentUser();
      setCurrentUser(user);
      setAuthenticationState(AuthenticationState.Authenticated);

      // setting user id for analytics
      setUserId(analytics, user.userId);
      setUserProperties(analytics, { user_phone_number: user.username });
    } catch (error) {
      setCurrentUser(null);
      setAuthenticationState(AuthenticationState.NotAuthenticated);
    } finally {
      setLoading(false);
    }
  }

  async function handleSignIn(phoneNumber: string) {
    try {
      setPhoneNumber(phoneNumber);
      const signInResponse = await signIn({
        username: phoneNumber,
        password: phoneNumber,
      });
      setAuthStep(signInResponse.nextStep.signInStep);
      setAuthenticationState(AuthenticationState.VerificationRequired);
      return {};
    } catch (singInError: any) {
      if (singInError.name === "UserNotFoundException") {
        try {
          const signUpResponse = await signUp({
            username: phoneNumber,
            password: phoneNumber,
            options: {
              userAttributes: {
                phone_number: phoneNumber,
              },
              autoSignIn: true,
            },
          });
          // log analytics event for sign up
          logEvent(analytics, "sign_up", {
            method: "AWS Amplify",
            user_phone_number: phoneNumber,
          });
          if (signUpResponse.nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN") {
            const autoSignInResponse = await autoSignIn();
            setAuthStep(autoSignInResponse.nextStep.signInStep);
            setAuthenticationState(AuthenticationState.VerificationRequired);
          } else {
            throw new Error("Something went wrong");
          }
          return {};
        } catch (signUpError: any) {
          if (signUpError.message) {
            return {
              error: signUpError.message,
            };
          }
          return { error: "Something went wrong" };
        }
      } else {
        if (singInError.message) {
          return {
            error: singInError.message,
          };
        }
        return { error: "Something went wrong" };
      }
    }
    return {};
  }

  async function verifyCode(code: string) {
    if (authStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE") {
      try {
        await confirmSignIn({
          challengeResponse: code,
        });
        setAuthStep("");
        setAuthenticationState(AuthenticationState.Authenticated);
        fetchUser();

        // log analytics event for sign in
        logEvent(analytics, "sign_in", {
          method: "AWS Amplify",
          user_phone_number: phoneNumber,
        });

        return {};
      } catch (error: any) {
        if (error.message) {
          fetchUser();
          return {
            error: error.message,
          };
        }
        fetchUser();
        return { error: "Something went wrong" };
      }
    }

    fetchUser();
    return { error: "Something went wrong" };
  }

  async function handleSignOut() {
    try {
      await signOut();
      fetchUser();
      return {};
    } catch (error: any) {
      if (error.message) {
        fetchUser();
        return {
          error: error.message,
        };
      }
      fetchUser();
      return { error: "Something went wrong" };
    }
  }

  const value: AuthContextValue = {
    currentUser,
    handleSignIn,
    handleSignOut,
    verifyCode,
    authenticationState,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
