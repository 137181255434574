import { SkinShade } from "../models/enums/skinShade";
import { SkinTone } from "../models/enums/skinTone";

// GENDER
export const MALE = "MALE";
export const FEMALE = "FEMALE";
// HEIGHT
export const SHORT = "SHORT";
export const NORMAL = "NORMAL";
export const TALL = "TALL";
//BODYSHAPE
export const RECTANGLE = "RECTANGLE";
export const INVERTED_TRIANGLE = "INVERTED_TRIANGLE";
export const TRAPEZOID = "TRAPEZOID";
export const TRIANGLE = "TRIANGLE";
export const OVAL = "OVAL";
//FORM
export const TOTAL_QUESTIONS = 5;

export const SKIN_TONES_1 = [
  "#f5d0c6",
  "#f2d2c5",
  "#efd2c2",
  "#edd2bf",
  "#eed5be",
  "#e9d5bc",
];

export const SKIN_TONES_2 = [
  "#e9b8b0",
  "#e8baaa",
  "#e4bba5",
  "#e3bea4",
  "#e0bea0",
  "#dfbfa0",
];

export const SKIN_TONES_3 = [
  "#dda196",
  "#d9a490",
  "#d9a78e",
  "#d6a988",
  "#d1aa87",
  "#cdab85",
];

export const SKIN_TONES_4 = [
  "#d18f81",
  "#cc8f7c",
  "#c99178",
  "#c59473",
  "#c09572",
  "#bc966f",
];

export const SKIN_TONES_5 = [
  "#be7a6f",
  "#ba7c67",
  "#b47c63",
  "#b17e5f",
  "#ad815c",
  "#a78558",
];

export const SKIN_TONES_6 = [
  "#aa685a",
  "#a46a52",
  "#a16c4d",
  "#9e6c4b",
  "#996f47",
  "#967145",
];

export const SKIN_TONES_7 = [
  "#935347",
  "#905841",
  "#8c593a",
  "#885c39",
  "#805d32",
  "#7f6031",
];

export const SKIN_TONES_8 = [
  "#7d4234",
  "#7b422f",
  "#78462b",
  "#744728",
  "#6f4925",
  "#6c4c23",
];

export const SKIN_TONES_9 = [
  "#682f26",
  "#653123",
  "#61341d",
  "#61351c",
  "#5c371c",
  "#573a1a",
];

export const SKIN_TONES_10 = [
  "#4d221b",
  "#4b2317",
  "#482512",
  "#422611",
  "#412710",
  "#40280e",
];

export const SKIN_CHART = [
  SKIN_TONES_1,
  SKIN_TONES_2,
  SKIN_TONES_4,
  SKIN_TONES_6,
  SKIN_TONES_8,
  SKIN_TONES_10,
];

export const ITERABLE_SKIN_SHADES = [
  SkinShade.SHADE_1,
  SkinShade.SHADE_2,
  SkinShade.SHADE_3,
  SkinShade.SHADE_4,
  SkinShade.SHADE_5,
  SkinShade.SHADE_6,
  SkinShade.SHADE_7,
];

export const ITERABLE_SKIN_TONES = [
  SkinTone.TONE_1,
  SkinTone.TONE_2,
  SkinTone.TONE_3,
  SkinTone.TONE_4,
  SkinTone.TONE_5,
  SkinTone.TONE_6,
  SkinTone.TONE_7,
];
