import { FC } from "react";
import styles from "./BodyShapeQuestionComponent.module.scss";
import { QuizDetails } from "../../../models/quizDetails";
import { BodyShape } from "../../../models/enums/bodyShape";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import ImageOptionComponent from "./ImageOptionComponent/ImageOptionComponent";

interface BodyShapeQuestionComponentProps {
  formData: QuizDetails | undefined;
  formDataChangeHandler: (newData: QuizDetails) => void;
}

const BodyShapeQuestionComponent: FC<BodyShapeQuestionComponentProps> = ({
  formData,
  formDataChangeHandler,
}) => {
  function updateFormData(bodyShapeSelected: BodyShape) {
    logEvent(analytics, "body_shape_selected", { value: bodyShapeSelected });
    formDataChangeHandler({ ...formData, bodyShape: bodyShapeSelected });
  }

  return (
    <div className={styles.BodyShapeQuestionComponent}>
      <div>
        <div className="text-center pt-3">
          <p className="h3 pb-3">Select your body shape</p>
          <div id="options" className="container">
            <div className="row">
              <ImageOptionComponent
                className="col-6"
                active={BodyShape.RECTANGLE === formData?.bodyShape}
                onClick={() => updateFormData(BodyShape.RECTANGLE)}
                imageSrc="illustrations/body-shapes/Rectangle.png"
              />
              <ImageOptionComponent
                className="col-6"
                active={BodyShape.INVERTED_TRIANGLE === formData?.bodyShape}
                onClick={() => updateFormData(BodyShape.INVERTED_TRIANGLE)}
                imageSrc="illustrations/body-shapes/InvertedTriangle.png"
              />
            </div>
            <div className="row">
              <ImageOptionComponent
                className="col-6"
                active={BodyShape.TRAPEZOID === formData?.bodyShape}
                onClick={() => updateFormData(BodyShape.TRAPEZOID)}
                imageSrc="illustrations/body-shapes/Trapezoid.png"
              />
              <ImageOptionComponent
                className="col-6"
                active={BodyShape.TRIANGLE === formData?.bodyShape}
                onClick={() => updateFormData(BodyShape.TRIANGLE)}
                imageSrc="illustrations/body-shapes/Triangle.png"
              />
            </div>
            <div className="row justify-content-center">
              <ImageOptionComponent
                className="col-6"
                active={BodyShape.OVAL === formData?.bodyShape}
                onClick={() => updateFormData(BodyShape.OVAL)}
                imageSrc="illustrations/body-shapes/Oval.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyShapeQuestionComponent;
