import { FC } from "react";
import styles from "./CallToActionSection.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/route-contants";
import { useAuth } from "../../../contexts/AuthContext";

interface CallToActionSectionProps {}

const CallToActionSection: FC<CallToActionSectionProps> = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  function navigateToSignup() {
    navigate(ROUTES.SIGNUP);
  }

  function navigateToFeed() {
    navigate(ROUTES.FEED);
  }

  function navigateToQuiz() {
    navigate(ROUTES.QUIZ);
  }

  return (
    <div className={styles.CallToActionSection + " text-center p-5"}>
      <h1 className={"display-5 fw-bold"}>Are You Ready To Get Starded?</h1>
      <div className="col-lg-6 mx-auto">
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center p-3">
          <button
            type="button"
            className="btn btn-primary btn-lg rounded-pill shadow"
            onClick={navigateToQuiz}
          >
            Take a free quiz
          </button>
          {currentUser ? (
            <button
              type="button"
              className="btn btn-outline-primary btn-lg rounded-pill"
              onClick={navigateToFeed}
            >
              My Feed
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-primary btn-lg rounded-pill"
              onClick={navigateToSignup}
            >
              Sign Up
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallToActionSection;
