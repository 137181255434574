import axios, { AxiosPromise, AxiosRequestConfig } from "axios";

export function httpGet<T>(
  url: string,
  config?: AxiosRequestConfig<any>
): AxiosPromise<T> {
  return axios.get(url, config);
}

export function httpPost<T>(
  url: string,
  payload: any,
  config?: AxiosRequestConfig<any>
): AxiosPromise<T> {
  return axios.post(url, payload, config);
}

export function httpPut<T>(
  url: string,
  payload: any,
  config?: AxiosRequestConfig<any>
): AxiosPromise<T> {
  return axios.put(url, payload, config);
}

export function httpDelete<T>(
  url: string,
  config?: AxiosRequestConfig<any>
): AxiosPromise<T> {
  return axios.delete(url, config);
}
