import { AxiosPromise } from "axios";
import { OutfitInfo } from "../models/outfitInfo";
import { httpGet } from "./rest-service";

export function getOutfit(
  outfitId: string | null | undefined
): AxiosPromise<OutfitInfo> {
  console.log("fetching outfit:", outfitId);
  return httpGet<OutfitInfo>(`/api/outfit/${outfitId}`);
}
