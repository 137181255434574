import { FC, useEffect } from "react";
import AuthenticationForm from "./AuthenticationForm/AuthenticationForm";
import FloatingCard from "../../components/FloatingCard/FloatingCard";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

interface AuthenticationPageProps {}

const AuthenticationPage: FC<AuthenticationPageProps> = () => {
  useEffect(() => {
    logEvent(analytics, "page_visit", { page: "auth" });
  }, []);
  return (
    <FloatingCard>
      <AuthenticationForm />
    </FloatingCard>
  );
};

export default AuthenticationPage;
