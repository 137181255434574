import { AxiosPromise } from "axios";
import { Like } from "../models/like";
import { httpGet, httpPost, httpPut } from "./rest-service";
import { AuthUser } from "aws-amplify/auth";

export function fetchUserLike(
  currentUser: AuthUser | undefined | null
): AxiosPromise<Like[]> {
  console.log("fetching like of user:", currentUser);
  return httpGet<Like[]>(`/api/like/${currentUser?.userId}`);
}

export function postLike(like: Like): AxiosPromise<Like> {
  console.log("posting like:", like);
  return httpPost<Like>("/api/like", like);
}

export function deleteLike(like: Like): AxiosPromise<Like> {
  console.log("deleting like:", like);
  return httpPut<Like>("/api/like", like);
}
